import React, { useEffect, useState } from 'react';
import './MyCustomChatMessage.css'



const MyCustomChatMessage = (props) => {
    const [time, setTime] = useState(new Date());
    const [read, setRead] = useState(new Date());
    const color = localStorage.getItem('chatbot') && JSON.parse(localStorage.getItem('chatbot')) && JSON.parse(localStorage.getItem('chatbot')).color;

    useEffect(() => {
        setTime(new Date());
        setRead(new Date());
    }, []);

    return (
        <div className={'message'}>
            <div className={'message-con-bot'} style={{background: color}} dangerouslySetInnerHTML={{ __html: props.message }}></div>
            <div className={'timeFloat'}>
                <span style={{color: '#3974d8'}}>{time && ' Envoyé le ' + time.toLocaleDateString() + ' à ' + time.toLocaleTimeString()}</span>
                {/*<br/>*/}
                {/*<b style={{color: '#01b75e'}}>{read && ' Lu le ' + read.toLocaleDateString() + ' à ' + read.toLocaleTimeString()}</b>*/}
            </div>
        </div>
    )
}

export default MyCustomChatMessage
