import './App.css';
import Chatbot from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';
import config from './bot/config.js';
import MessageParser from './bot/MessageParser.js';
import ActionProvider from './bot/ActionProvider.js';
import React, { useEffect, useState } from 'react';
import { getChatbot, getEntreprise, getChatById } from "./api/service";
import { createChatBotMessage } from 'react-chatbot-kit';

function App() {
    const [botConfig, setBotConfig] = useState(null); // Initialiser avec null
    const [loading, setLoading] = useState(true); // État de chargement
    const [chatbotId, setChatBotId] = useState(null); // État de chargement

    // Sauvegarde des messages, modules et autres données du chatbot dans localStorage sous l'objet 'chatbots'
    const saveMessages = (messages) => {
        const chatbotsData = JSON.parse(localStorage.getItem('chatbots')) || {};
        if (chatbotId) {
            // Conserver tous les éléments sauf les chat_messages actuels
            chatbotsData[chatbotId] = {
                ...chatbotsData[chatbotId],
                chat_messages: messages // Mettre à jour uniquement les messages
            };
            localStorage.setItem('chatbots', JSON.stringify(chatbotsData));
        }
    };

    // Chargement des messages depuis le localStorage
    const loadMessages = () => {
        const chatbotsData = JSON.parse(localStorage.getItem('chatbots')) || {};
        return chatbotId && chatbotsData[chatbotId] ? chatbotsData[chatbotId].chat_messages : [];
    };

    useEffect(() => {
        const getIdChat = async () => {
            try {
                let chat
                const chat_bot_id = window.location.href.includes('chatbot_id=') 
                    ? window.location.href.split('chatbot_id=')[1].split('&')[0] 
                    : null;

                setChatBotId(chat_bot_id);
                localStorage.setItem("chatbot_id_active", chat_bot_id);

                const chatbotsData = JSON.parse(localStorage.getItem('chatbots')) || {};
                const chat_id_active = chatbotsData[chat_bot_id]?.chat_id || null
                if(chat_id_active){
                    chat = await getChatById(chat_id_active) 
                }
                
                if (chatbotsData[chat_bot_id] && chat && chat?.data?.status != 'end') {
                    // Si le chatbot existe déjà, récupérer les données depuis l'API et conserver les messages existants
                    const chatbotFromAPI = await getChatbot(chat_bot_id);
                    
                    if (chatbotFromAPI && chatbotFromAPI.data) {
                        const chatbotData = chatbotFromAPI.data;
                        const existingMessages = chatbotsData[chat_bot_id].chat_messages; // Conserver les messages existants
                      
                        // Mettre à jour la config avec les nouvelles données API
                        setBotConfig({
                            ...config,
                            initialMessages: existingMessages, // Garder les messages actuels
                            modules: chatbotData.modules,
                            entreprise: chatbotsData[chat_bot_id].entreprise,
                        });

                        // Mettre à jour les autres éléments dans localStorage sauf les messages
                        chatbotsData[chat_bot_id] = {
                            ...chatbotsData[chat_bot_id], // Garder les messages actuels
                            id: chatbotData.id,
                            modules: chatbotData.modules,
                            messages: chatbotData.messages, // Messages du chatbot (intro, outro, etc.)
                        };
                        localStorage.setItem('chatbots', JSON.stringify(chatbotsData));
                    }
                } else {
                    // Si le chatbot n'existe pas dans le localStorage, récupérer les données via l'API et stocker tout
                    const data = await getChatbot(chat_bot_id);
                    if (data && data.data && data.data.id && data.data.modules && data.data.messages) {
                        const intro = data.data.messages.intro || 
                            `Salut ! Je suis l'intelligence artificielle conçue pour répondre à vos questions et résoudre vos problèmes. Comment puis-je vous assister ?`;

                        const entreprise = await getEntreprise(data.data.entreprise_id);
                        const entrepriseData = entreprise && entreprise.data && entreprise.data.nom
                            ? { id: entreprise.data.id, nom: entreprise.data.nom }
                            : {};

                        // Mettez à jour la configuration du chatbot avec les nouvelles données
                        setBotConfig({
                            ...config,
                            initialMessages: [createChatBotMessage(intro)], // Message d'intro
                        });

                        // Stocker toutes les données dans localStorage
                        chatbotsData[chat_bot_id] = {
                            id: data.data.id,
                            chat_messages: [createChatBotMessage(intro)], // Initialiser avec le message d'introduction
                            entreprise: entrepriseData,
                            modules: data.data.modules,
                            messages: data.data.messages, // Messages du chatbot (par exemple intro, outro, etc.)
                        };

                        delete chatbotsData[chat_bot_id]['chat_id']
                        
                        localStorage.setItem('chatbots', JSON.stringify(chatbotsData));
                    }
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false); // Fin du chargement
            }
        };

        getIdChat();
    }, []);

    useEffect(() => {
        if (!loading && document.querySelector('.react-chatbot-kit-chat-btn-send')) {
            // Manipuler l'élément DOM après que React l'ait rendu
            document.querySelector('.react-chatbot-kit-chat-btn-send').innerHTML = `
                <svg class="react-chatbot-kit-chat-btn-send-icon" xmlns="http://www.w3.org/2000/svg" width="71" height="70" viewBox="0 0 71 70">
                    <g> <path d="M26.4168 54.9786C25.9023 55.48 25.2958 55.6696 24.5973 55.5476C23.8987 55.4256 23.4105 55.0289 23.1326 54.3574L19.5683 45.7441L32.2821 36.4845L16.7429 38.9163L13.1786 30.303C12.9007 29.6316 12.9658 29.0058 13.3739 28.4259C13.7819 27.8459 14.3451 27.5516 15.0635 27.5428L48.5104 27.7651C49.4097 27.791 50.0255 28.2056 50.3579 29.0088C50.6903 29.8121 50.5465 30.5381 49.9264 31.1869L26.4168 54.9786Z"/>
                    </g>
                </svg>`;
        }
    }, [loading]);

    if (loading) {
        return <div>Chargement...</div>; // Affichage pendant le chargement
    }

    const resetChat = () => {
        const chatbotsData = JSON.parse(localStorage.getItem('chatbots')) || {};
        const chatbotId = window.location.href.includes('chatbot_id=') 
        ? window.location.href.split('chatbot_id=')[1].split('&')[0] 
        : null;
        if (chatbotId) {
            delete chatbotsData[chatbotId]['chat_id']
            delete chatbotsData[chatbotId]['chat_messages']
        // Sauvegarde dans localStorage
        localStorage.setItem('chatbots', JSON.stringify(chatbotsData));

        window.location.reload()
        }
    };

    return (
        <div>
            {botConfig && (
                <Chatbot
                    config={botConfig}
                    headerText='MCA'
                    messageParser={MessageParser}
                    actionProvider={ActionProvider}
                    saveMessages={saveMessages}
                    messageHistory={loadMessages()}
                    placeholderText='Composez votre message...'
                    validator={(msg) => msg.length > 0 && msg.length < 401}
                />
            )}
            <button onClick={resetChat} style={{ marginTop: '10px', padding: '10px', cursor: 'pointer' }}>
                Redémarrer le chat
            </button>
        </div>
    );
}

export default App;